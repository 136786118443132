import { useEffect, useState } from "react"
import { API_URL } from "../../../utils/config"
import moment from "moment"

export const useEmployeesInstallations = () => {

    const [list, setLists] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [options, setOptions] = useState({from: moment().startOf("day").format(), to: moment().endOf("day").format(), status: "completed"})

    const handleInputChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        setOptions((prev) => ({
            ...prev, [name]: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        getData(options)
    }


    const getData = async (options) => {
        try {
            setIsLoading(true)
            const response = await fetch(`${API_URL}/team/employees/getInstallationsByTeam?from=${options.from}&to=${options.to}&status=${options.status}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            })

            if (response.ok) {
                const data = await response.json()                
                setLists([...data])
            }
            
        } catch (error) {
            console.log(error);
            
        }finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getData(options)
    }, [])

    return {
        list,
        isLoading,
        options,
        handleInputChange,
        handleSubmit
    }
}