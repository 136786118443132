import React, { useEffect } from 'react';
import { useEmployeesInstallations } from '../../../../../hooks/field-support/employees-installations/useEmployeesInstallations';
import LoadingSpinner from '../../../../layouts/LoadingSpinner';

export default function EmployeesInstallationsBody() {
    const {list, isLoading, options, handleInputChange, handleSubmit} = useEmployeesInstallations()

    return <main>
         <form className="ml-3 form-inline" style={{marginTop: "5rem"}} onSubmit={handleSubmit}>

            <div className="form-group d-flex">
                <small className="mr-1 ml-3 copy-font">From:</small>
                    <input
                    placeholder=""
                    type="date"
                    classname="form-control"
                    name="from"
                    value={options.from}
                    onChange={handleInputChange}
                    />
                    <small className="mr-1 ml-3 copy-font">To:</small>
                    <input
                    placeholder=""
                    type="date"
                    classname="form-control"
                    name="to"
                    value={options.to}
                    onChange={handleInputChange}
                    />
                    </div>
            <div className="">
                <div className="form-group mr-2">
                    <small className="mr-1 ml-3 copy-font">Status:</small>
                        <select
                            className="form-control"
                            id="status"
                            name="status"
                            value={options.status}
                            onChange={handleInputChange}
                        >
                            {/* <option value="">Select an option</option> */}
                            <option value="completed">Completed</option>
                            <option value="queued">Queued</option>
                            <option value="failed">Failed</option>
                            <option value="awaiting_account_creation">Awaiting Account Creation</option>
                            <option value="on-going">Ongoing</option>
                            <option value="no-los">No Los</option>
                            <option value="not completed">Not Completed</option>
                        </select>

                </div>
            </div>
            <div className="text-center mr-3">
                <button type="submit" className="btn btn-green py-2 px-5">
                Search
                </button>
            </div>
        </form>

        <p className='my-4' style={{fontSize: "1rem", textTransform: "capitalize"}}>
            Installation Status: {options.status}
        </p>

        <Table list={list} isLoading={isLoading}/>
    </main>
}


const Table = ({list, isLoading}) => {

        const tableData =  (list && list.length > 0 && list.map((item, i) => {
        const team = item.team || {name: "N/A"}
     
        return (
            <tr className="text-nowrap" key={i}>
            <td>{i + 1}</td>
            <td>{`${item.firstName} ${item.lastName}`}</td>
            <td>{team.name}</td>
            <td>{item.installations.length || "N/A"}</td>
       
          </tr>
        )
    }))

    return (
        <div className="table-responsive content col-12 px-1">
        <table className="table">
          <thead>
            <tr className="">
              <th>#</th>
              <th className="text-nowrap">Employee Name</th>
           
              <th className="text-nowrap">Team Name</th>
              <th className="text-nowrap">No. of Installation</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center">
                  {/* <img src={LoadingSpinner} alt="loading gif" /> */}
                  <LoadingSpinner/>
                </td>
              </tr>
            ) : (
              tableData
            )}
          </tbody>
        </table>
    
      </div>
    )
    

}