const URLS = {
  ADMIN_HOME_URL: '/',
  LOGIN_URL: '/login',
  FORGOT_PASSWORD_URL: '/forgot-password',

  // accounts
  ACCOUNTS_OVERVIEW: '/accounts',
  ACCOUNTS_ALL_APPROVED_REQUISITIONS: `/accounts/all-approved-requisitions`,
  ACCOUNTS_REQUISITIONS: `/accounts/requisition`,
  ACCOUNTS_PAID_REQUISITION: `/accounts/paid-requisition`,
  ACCOUNTS_NO_LOS: `/accounts/no-los`,
  ACCOUNTS_ADMIN_REQUESTS: `/accounts/cfo/admin-requests`,
  CFO_EXPRESS_WIFI_RETAILER_SMS: `/accounts/cfo/retailer-sms`,
  ACCOUNTS_TRACKLIST_CATEGORY: `/accounts/tracklist-category`,
  ACCOUNTS_REFUNDS: `/shared/refunds`,
  ACCOUNTS_REFUNDS_ANALYTICS: `/shared/refunds/analytics`,
  ACCOUNTS_STAFF_PAYSLIPS: `/accounts/staff-payslips`,
  ACCOUNTS_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',

  // asset team
  ASSET_TEAM_CLOSED_LEADS: '/closed-leads',
  ASSET_TEAM_ACTIVE_PER_COUNTRY: '/asset-team/active-per-country',
  ASSET_TEAM_EXPIRED_PER_COUNTRY: '/asset-team/expired-per-country',
  ASSET_TEAM_INSTALLED_PER_COUNTRY: '/asset-team/installed-per-country',
  ASSET_TEAM_ACTIVE_AND_INACTIVE: '/asset-team/active-and-inactive',
  ASSET_TEAM_EXPIRED_LTE_CUSTOMERS: '/asset-team/expired-lte-customers',
  ASSET_TEAM_ACTIVE_LTE_CUSTOMERS: '/asset-team/active-lte-customers',
  ASSET_TEAM_LTE_INSTALLATIONS: '/asset-team/lte-installations',
  ASSET_TEAM_DEVICE_RETRIEVAL: '/asset-team/device-retrieval',
  ASSET_TEAM_TRACKER: '/asset-team/customers-tracker',
  ASSET_TEAM_ACTIVE_CUSTOMERS: '/asset-team/active-customers',
  ASSET_TEAM_INSTALLATIONS_PER_BASESTATIONS: '/asset-team/installations-per-basestations',
  ASSET_TEAM_GENIATECH: '/asset-team/geniatech',
  ASSET_TEAM_PAYSLIP: '/asset-team/payslip',
  ASSET_TEAM_KPI_MONITOR: '/asset-team/kpi-monitor',
  QA_INSTALLATION_REPORT: '/shared/qa-installations',

  // SUPER ADMIN
  SUPER_ADMIN_ALL_APPROVED_REQUISITIONS: `/super-admin/all-requisitions`,
  SUPER_ADMIN_REFUNDS: `/shared/refunds`,
  SUPER_ADMIN_REQUISITIONS: `/super-admin/requisitions`,
  SUPER_ADMIN_RETENTION: `/super-admin/retention`,
  // SUPER_ADMIN_RELOCATION: `/super-admin/relocation`,
  // SUPER_ADMIN_LTE_KYC: `/super-admin/lte-kyc`,
  // SUPER_ADMIN_INSTALLATIONS: `/super-admin/installations`,
  // SUPER_ADMIN_EMPLOYEES: `/super-admin/employees`,
  // SUPER_ADMIN_PURCHASE_ORDER: `/super-admin/purchase-orders`,
  // SUPER_ADMIN_DEVICE_RETRIEVAL: `/super-admin/device-retrievals`,
  // SUPER_ADMIN_CUSTOMERS_TRACKER: `/super-admin/customers-tracker`,
  // SUPER_ADMIN_LTE_INSTALLATIONS: `/super-admin/lte-installations`,
  // SUPER_ADMIN_LTE_EXPIRED_CUSTOMERS: `/super-admin/expired-lte-customers`,
  // SUPER_ADMIN_LTE_ACTIVE_CUSTOMERS: `/super-admin/active-lte-customers`,
  // SUPER_ADMIN_INSTALLATIONS_PER_COUNTRY: `/super-admin/installations-per-country`,
  // SUPER_ADMIN_QUALITY_ASSURANCE: `/super-admin/quality-assurance`,
  // SUPER_ADMIN_RETENTION_LOGS: `/super-admin/retention-logs`,
  // SUPER_ADMIN_PAID_LEADS: `/paid-leads`,
  SUPER_ADMIN_MAILBLAST: `/super-admin/mail-blast`,
  SUPER_ADMIN_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',

  // noc
  NOC_OVERVIEW: `/network-operations`,
  NOC_PAYSLIP: `/network-operations/payslip`,
  NOC_BUDGET: `/network-operations/budget`,
  NOC_ACCESS_POINT_CONNECTION: `/network-operations/access-point-connection`,
  NOC_HOTSPOT_SERVER: `/network-operations/hotspot-server`,
  NOC_ENB_SITE_IP_ALLOCATION: `/network-operations/enb-site-ip-allocation`,
  NOC_PTP: `/network-operations/ptp`,
  NOC_FACEBOOK_SITES: `/network-operations/facebook-sites`,
  NOC_SNMP: `/network-operations/snmp`,
  NOC_ROUTER_IP_ADDRESS: `/network-operations/router-ip-address`,
  NOC_SECTOR_ALLOCATION: `/network-operations/sector-allocation`,
  NOC_SNMP_CACTI: `/network-operations/snpm-cacti`,
  NOC_SHEET_DEDICATED_CUSTOMER: `/network-operations/noc-sheet/dedicated-customers`,
  NOC_FIBER_PPTP: `/network-operations/fiber-pptp`,
  NOC_MAIL_BLAST: `/network-operations/mail-blast`,
  NOC_BASE_STATION_CONNECTION: `/network-operations/basestation-connection`,
  NOC_PTP_VLAN_IP: `/network-operations/ptp-vlan-ip`,
  NOC_CNMAESTRO_TOWERS: `/network-operations/cnmaestro/towers`,
  NOC_CNMAESTRO_TOWERS_INFORMATION: `/network-operations/cnmaestro/towers`,
  NOC_CNMAESTRO_STATISTICS: `/network-operations/cnmaestro/statistics`,
  NOC_CNMAESTRO_SITES: `/network-operations/cnmaestro/sites`,
  NOC_CNMAESTRO_PERFORMANCE: `/network-operations/cnmaestro/device-performance`,
  NOC_CNMAESTRO_NETWORKS: `/network-operations/cnmaestro/networks`,
  NOC_CNMAESTRO_TOKENS: `/network-operations/cnmaestro/token`,
  NOC_CNMAESTRO_ALARMS: `/network-operations/cnmaestro/alarms`,
  NOC_CNMAESTRO_DEVICES: `/network-operations/cnmaestro/devices`,
  NOC_COMPLETED_SITES: `/network-operations/completed-sites`,
  NOC_SUB_BASESTATION: `/network-operations/sub-basestation`,
  NOC_CPU_UTILIZATION_OF_FIBER_SITES: `/network-operations/fiber-sites/cpu-utilization`,
  NOC_LTE_ENB_IP: `/network-operations/lte/enb-ip`,
  NOC_MDXI_CIRCUITS: `/network-operations/mdxi-circuits`,
  NOC_STATIC_IP_CUSTOMERS: `/network-operations/static-ip/customers`,
  NOC_FIBER_SITES_PUBLIC_IP: `/network-operations/fiber-sites/public-ip`,
  NOC_IP_ALLOCATION: `/network-operations/ip-allocation`,
  NOC_ALLOCATION_PTP: `/network-operations/ip-allocation-ptp`,
  NOC_PUBLIC_IP_ALLOCATION: `/network-operations/public-ip-allocation`,
  NOC_LISTS_OF_BASESTATIONS: `/network-operations/lists-of-basestations`,
  NOC_STATIC_ACCESS_POINTS: `/network-operations/static-access-points`,
  NOC_REBOOT: `/network-operations/reboot`,
  NOC_CAMBIUM_LICENSE_KEYS: `/network-operations/cambium-license-keys`,

  // lte
  NOC_LTE_ACCOUNTS: `/network-operations/lte/accounts`,
  NOC_LTE_ACTIVE_CUSTOMERS: `/network-operations/lte-active-customers`,
  NOC_LTE_EXPIRED_CUSTOMERS: `/network-operations/lte-expired-customers`,
  NOC_LTE_PROFILE: `/network-operations/lte/profiles`,
  NOC_LTE_SUBSCRIBERS: `/network-operations/lte/subscribers`,
  NOC_LTE_EXPIRED_SUBSCRIBERS: `/network-operations/lte/expired-subscribers`,

  // CUSTOMER SUPPORT
  CUSTOMER_SUPPORT_OVERVIEW: '/customer-support',
  CUSTOMER_SUPPORT_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',
  CUSTOMER_SUPPORT_PAYSLIP: `/customer-support/payslip`,
  CUSTOMER_SUPPORT_PAYMENT_HISTORY: `/customer-support/customer/payment-history`,
  CUSTOMER_SUPPORT_UNVERIFIED_CUSTOMERS: `/customer-support/unverified-customers`,
  CUSTOMER_SUPPORT_BUDGET: `/customer-support/budget`,
  CUSTOMER_SUPPORT_CALL_LOGS: `/customer-support/call-logs`,
  CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS: `/customer-support/active-customers`,
  CUSTOMER_SUPPORT_ASSET_EXPIRED: `/customer-support/asset-team/total-expired`,
  CUSTOMER_SUPPORT_ASSET_SEVEN_DAYS_EXPIRED: `/customer-support/asset-team/expired-seven-days`,
  CUSTOMER_SUPPORT_ASSET_EXPIRED_ZONES: '/customer-support/asset-team/expired-zones',
  CUSTOMER_SUPPORT_ASSET_TEAM_INSTALLATIONS: '/customer-support/asset-team/installations',
  CUSTOMER_SUPPORT_CREATE_CUSTOMER: '/customer-support/create-customer',
  CUSTOMER_SUPPORT_ACTIVE_LTE_CUSTOMERS: `/customer-support/active-lte-customers`,
  CUSTOMER_SUPPORT_GHANA_CUSTOMER_REQUISITIONS: `/ghana/requisitions`,
  CUSTOMER_SUPPORT_EXPIRED_LTE_CUSTOMERS: `/customer-support/expired-lte-customers`,
  CUSTOMER_SUPPORT_LTE_INSTALLATIONS: `/customer-support/lte-installations`,
  CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS: '/customer-support/active-and-inactive',
  CUSTOMER_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS_RESTRICTED: '/customer-support/restricted/active-and-inactive',
  CUSTOMER_SUPPORT_ACTIVE_CUSTOMERS_PER_COUNTRY: '/customer-support/active-customers/per-country',
  CUSTOMER_SUPPORT_EXPIRED_CUSTOMERS_PER_COUNTRY: '/customer-support/expired-customers/per-country',
  CUSTOMER_SUPPORT_INSTALLATIONS_PER_COUNTRY: '/customer-support/installations/per-country',
  CUSTOMER_SUPPORT_DEVICE_RETRIEVAL: '/customer-support/device-retrieval',
  CUSTOMER_SUPPORT_RETENTION_LOGS: '/customer-support/retention-logs',
  CUSTOMER_SUPPORT_FAILED_PAYSTACK_TRANSACTIONS: '/customer-support/paystack-transactions',
  CUSTOMER_SUPPORT_TRACKER: '/customer-support/customers-tracker',
  CUSTOMER_SUPPORT_LTE_CUSTOMERS: `/customer-support/lte-customers`,
  CUSTOMER_SUPPORT_DELISTED_CUSTOMERS: `/customer-support/delisted-customers`,
  CUSTOMER_SUPPORT_CALL_REPORT: `/customer-support/call-report`,
  CUSTOMER_SUPPORT_BULK_CUSTOMER_DETAILS: `/customer-support/bulk-customer-details`,
  CUSTOMER_SUPPORT_INSTALLATION_FEEDBACK: '/shared/installation-feedback',

  // EXPRESS WIFI
  EXPRESS_WIFI_OVERVIEW: `/express-wifi`,
  EXPRESS_WIFI_PAYSLIP: `/express-wifi/payslip`,
  EXPRESS_WIFI_RETAILER_SMS: `/express-wifi/sms`,
  EXPRESS_WIFI_GENIATECH: `/express-wifi/geniatech`,
  EXPRESS_WIFI_CUSTOMERS: `/express-wifi/customers`,
  EXPRESS_WIFI_RETAILERS: `/express-wifi/retailers`,
  EXPRESS_WIFI_KPI_MONITOR: `/express-wifi/kpi-monitor`,

  // field support
  FIELD_SUPPORT_REFUND_LIST: `/shared/refunds`,
  FIELD_SUPPORT_OVERVIEW: `/field-support`,
  FIELD_SUPPORT_PAYSLIP: `/field-support/payslip`,
  FIELD_SUPPORT_INSTALLATIONS_SUMMARY: `/field-support/installations-summary`,
  FIELD_SUPPORT_INSTALLATIONS_COUNTER: `/field-support/installations-counter`,
  FIELD_SUPPORT_RELOCATION: `/field-support/relocation`,
  FIELD_SUPPORT_SCHEDULING: `/field-support/scheduling`,
  FIELD_SUPPORT_GHANA_REQUISITIONS: `/ghana/requisition`,
  FIELD_SUPPORT_REQUISITIONS: `/field-support/requisitions`,
  FIELD_SUPPORT_INSTALLATION_OVERVIEW: `/field-support/installations/leads-and-manager`,
  FIELD_SUPPORT_USER_ASSIGNED_INSTALLATION: `/assigned-installations`,
  FIELD_SUPPORT_ASSIGN_USER_AN_INSTALLATION: `/assign-installation`,
  FIELD_SUPPORT_DEVICE_RETRIEVAL: `/field-support/device-retrievals`,
  FIELD_SUPPORT_BUDGET: '/field-support/budget',
  FIELD_SUPPORT_INSTALLATION_SUB_ZONES: `/installation-sub-zones`,
  FIELD_SUPPORT_AVAILABLE_INSTALLATION_TIMES: `/available-installation-times`,
  FSE_CLUSTERED_INSTALLATIONS: `/field-support/clustered-installations`,
  FIELD_SUPPORT_SUPPORT_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',
  FIELD_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS: '/field-support/active-and-inactive',
  FIELD_SUPPORT_EMPLOYEES_INSTALLATIONS: '/field-support/employees-installations',

  // new products
  NEW_PRODUCTS_OVERVIEW: `/new-products`,
  NEW_PRODUCTS_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',
  NEW_PRODUCTS_PAYSLIP: `/new-products/payslip`,
  NEW_PRODUCTS_BUDGET: `/new-products/budget`,
  // STATISTICS: `/`

  // procurement
  PROCUREMENT_OVERVIEW: `/procurement`,
  PROCUREMENT_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',
  PROCUREMENT_PAYSLIP: `/procurement/payslip`,
  PROCUREMENT_TRACKLIST_CATEGORY: `/procurement/tracklist-category`,
  PROCUREMENT_CLEAN_RADIO: `/procurement/clean-radio`,

  // reasearch and development
  RD_OVERVIEW: `/research-and-development`,
  RD_BUDGET: `/research-and-development/budget`,
  RESEARCH_AND_DEV_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',

  // sales
  SALES_OVERVIEW: `/sales`,
  SALES_PAYSLIP: `/sales/payslip`,
  SALES_KYC: `/sales/lte-kyc`,
  SALES_BUDGET: `/sales/budget`,
  SALES_DEDICATED_CUSTOMERS: `/sales/dedicated-customers`,
  SALES_RESCHEDULED_INSTALLATIONS: `/sales/rescheduled-installations`,
  SALES_RESCHEDULE_INSTALLATIONS: `/sales/reschedule-installations`,
  SALES_ASSET_TEAM_INSTALLATIONS: `/sales/asset-team-installations`,
  SALES_CLUSTERED_INSTALLATIONS: `/sales/clustered-installations`,
  SALES_INSTALLATIONS_COUNTER_SUMMARY: `/sales/installations-summary`,
  SALES_INSTALLATIONS_PER_BASESTATION: `/sales/installations-per-basestation`,
  SALES_CUSTOMER_INVOICE: '/sales/customer-invoice',
  SALES_EXISTING_INVOICE: '/sales/existing-invoice',
  SALES_REFUND: '/shared/refunds',
  SALES_INSTALLATIONS_DATE_RANGE: `/sales/installations-date-range`,
  SALES_RECRUITMENT: 'https://tizeti.com/careers/admin/dashboard',

  //partnership
  PARTNERSHIP_OVERVIEW: `partnership`,

  // special assignment
  SPECIAL_ASSIGNMENT: `/special-assignment`,
  SPECIAL_ASSIGNMENT_BASESTATIONS: `/special-assignment/base-stations`,
  SPECIAL_ASSIGNMENT_RETENTION: `/special-assignment/retention`,
  SPECIAL_ASSIGNMENT_USAGE_HISTORY: `/special-assignment/usage-history`,
  SPECIAL_ASSIGNMENT_EXPIRED: `/special-assignment/expired`,
  SPECIAL_ASSIGNMENT_CUSTOMERS: `/special-assignment/customers`,
  SCHEDULE_USER: `/v2/schedule`,

  // WIFICALL
  WIFICALL_OVERVIEW: `/wifi-call`,
  WIFICALL_FREE_SETUP_SIGNUP: `/wifi-call/free-signups`,
  WIFICALL_OPEN_BLOCKED_USERS: `/wifi-call/open-and-blocked-users`,

  VIEW_ONE_REQUISITION: `/requisition`,
  /**
   * business development
   */
  BUSINESS_DEVELOPMENT_OVERVIEW: '/business-development',
  BUSINESS_DEVELOPMENT_DEDICATED_CUSTOMERS: '/business-development/dedicated-customers',
  BUS_DEV_GHANA_REQUISITION: '/business-development/gh/requisitions',
  BUS_DEV_NIGERIA_REQUISITION: '/business-development/ng/requisitions',
  BUSINESS_DEVELOPMENT_PAYSLIP: '/business-development/payslip',
  BUSINESS_DEVELOPMENT_KPI_MONITOR: '/business-development/kpi-monitor',
};

export default URLS;
