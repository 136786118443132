import { useEffect, useState } from "react"
import { API_URL } from "../../utils/config"
import toastr from "toastr"

const today = new Date().toISOString().split("T")[0]

const one_day = new Date()

one_day.setHours(one_day.getHours() - 24)

const from = new Date(one_day).toISOString().split("T")[0]

export const useCompletedInstallations = () => {

    const [page, setPage] = useState(1)

    const [list, setList] = useState([])

    const [options, setOptions] = useState({from: from, to: today, country: "ng", zone_id: ""})

    const [zoneLoading, setZoneLoading] = useState(false)

    const [zoneList, setZoneList] = useState([])

    const [allPages, setAllPages] = useState(0)

    const [count, setCount] = useState(0)

    const [isLoading, setIsLoading] = useState(true)



    const handlePageClick = (e) => {
        const selectedPage = e.selected + 1;
        setPage(selectedPage)
        getCompletedInstallations(selectedPage)
    }

    const handleSetOptions = (e) => {
        const name = e.target.name
        const value = e.target.value
        setOptions((prev) => ({...prev, [name]: value}))
    }


    const getCompletedInstallations = async (page, options) => {

        if (!options.zone_id) {
            delete options.zone_id
        }

        if (!options.from || !options.to) {
            toastr.info("Please select a valid date range")
            return
        }

        if (!options.country) {
            options["country"] = "ng"
        }

        const body = {
            paginate: true,
            ...options
        }

        try {
            setIsLoading(true)
            const res = await fetch(`${API_URL}/account/getCompletedInstallations/${page}`, {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(body),
              });

              if (res.ok) {
                const comp = await res.json()
                
                
                setList([...comp.list])
                setAllPages(comp.pages)
                setCount(comp.count)
              }else {
                toastr.error("Something went wrong")
              }
        } finally {
            setIsLoading(false)
        }
    }

    const getZones = async () => {
        setZoneLoading(true)
        try {
            const res = await fetch(`${API_URL}/zones`)

            if (res.ok) {
                const zones = await res.json()

                setZoneList([...zones])
            }
        } finally {
            setZoneLoading(false)
        }
    }

    useEffect(() => {
        getCompletedInstallations(page, options)
        getZones()
    }, [])

    return {
        list,
        page,
        handlePageClick,
        isLoading,
        allPages,
        count,
        zoneLoading,
        zoneList,
        options,
        handleSetOptions,
        getCompletedInstallations
    }
}