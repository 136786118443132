/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FieldSupportSideBarItem from './FieldSupportSideBarItem';
import PATHS from '../../../../routes/urls';
import { AES, enc } from 'crypto-js';
// eslint-disable-next-line import/no-named-as-default
import BudgetSideBar from '../../../staff/shared/layouts/sidebar/manager-and-executives/BudgetSideBar';
import RequisitionNotification from '../../../staff/shared/layouts/sidebar/manager-and-executives/RequisitionNotification';
import { useUserProfile } from '../../../../hooks/shared/user/useUserProfile';

const FieldSupportSideBar = () => {
  const roleId = Number(sessionStorage.getItem('roleId'));
  const country = sessionStorage.getItem('country');
  const email = sessionStorage.getItem('officialEmail');
  const detailsState = {
    name: 'React',
    messagetoken: sessionStorage.getItem('token'),
    messagerole: sessionStorage.getItem('roleId'),
    messagedepartment: sessionStorage.getItem('departmentId'),
    secret: 'Wifirocks123',
    cipher: '',
  };
  const [details, setDetails] = useState(detailsState);
  const { name, messagetoken, messagerole, messagedepartment, secret, cipher } = details;

  const obj = {
    login: messagetoken,
    pass: messagerole,
    department: messagedepartment,
  };

  const ciph = AES.encrypt(JSON.stringify(obj), secret).toString();

  const checkCountry = () => {
    if (email === 'christian.onyegwu@tizeti.com' && Number(`${sessionStorage.getItem('roleId')}`) === 3) {
      return (
        <li className="s-sidebar__nav-link">
          <Link to={PATHS.FIELD_SUPPORT_GHANA_REQUISITIONS} target="_self" className="d-flex">
            <span>
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>{' '}
            Ghana Requisitions
            <RequisitionNotification />
          </Link>
        </li>

        
      );
    }
    if (Number(`${sessionStorage.getItem('roleId')}`) === 3) {
      return (
        <li className="s-sidebar__nav-link">
          <Link to={PATHS.FIELD_SUPPORT_REQUISITIONS} target="_self" className="d-flex">
            <span>
              <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
            </span>{' '}
            Requisition
            <RequisitionNotification />
          </Link>
        </li>
      );
    }
    return (
      <li className="s-sidebar__nav-link">
        <Link to={PATHS.FIELD_SUPPORT_REQUISITIONS} target="_self" className="d-flex">
          <span>
            <img src="https://res.cloudinary.com/teewhy/image/upload/v1550844527/requisition.png" alt="img" />
          </span>{' '}
          Requisition
        </Link>
      </li> 
    );

  };
  return (
    <div className="s-layout__sidebar">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link className="s-sidebar__trigger" to="#">
        <i className="fa fa-bars mr-3" />
      </Link>

      <nav className="s-sidebar__nav">
        <Link className="navbar-brand-logo" to="/field-support">
          <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971047/Tizeti-Logo.png" alt="homepage" />
        </Link>
        <ul>
          {/* <span className="p-0 mt-3"> */}
          <li className="s-sidebar__nav-link">
            <Link to="/field-support" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971013/Group-3.png" alt="img" />
              </span>
              <small>Overview</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {' '}
            <a href={`https://os.tizeti.com/main/#/overview?ciph=${ciph}`} target="_blank" className="make-bold">
              <span>
                <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png" alt="img" />
              </span>
              <small>New system (Beta v2.0)</small>
            </a>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/teams" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971014/Group-6.png" alt="img" />
              </span>
              <small>Teams</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {' '}
            <Link to="/field-support/customers" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
              </span>
              <small>Customers</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {' '}
            <Link to="/device-type" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
              </span>
              <small>Device Types</small>
            </Link>
          </li>
          {roleId > 1 ? (
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/field-support/customer-details" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>{' '}
                Customer Details
              </Link>
            </li>
          ) : (
            ''
          )}

          {roleId === 3 ? (
            <li className="s-sidebar__nav-link">
              {' '}
              <Link to="/shared/installation-feedback" target="_self">
                <span>
                  <img src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png" alt="img" />
                </span>{' '}
                Installation Feedback
              </Link>
            </li>

            
          ) : (
            ''
          )}
          <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_OVERVIEW} name="Installations" />
          <FieldSupportSideBarItem url="/installations/reschedule" name="Rescheduled Installations" />

          <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATION_SUB_ZONES} name="Installation Sub Zones" />

          <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_INSTALLATIONS_COUNTER} name="Installations Summary" />

          <FieldSupportSideBarItem url={PATHS.FIELD_SUPPORT_DEVICE_RETRIEVAL} name="Retrievals" />

          {(email === 'nsikak.asuquo@tizeti.com' || email === 'operationsDept@tizeti.com') && (
            <FieldSupportSideBarItem
              url={PATHS.FIELD_SUPPORT_ACTIVE_AND_INACTIVE_CUSTOMERS}
              name="Active/Inactive Customers"
            />
          )}

          {Number(`${sessionStorage.getItem('roleId')}`) === 3 && (
            <li className="s-sidebar__nav-link">
              <Link to={PATHS.FIELD_SUPPORT_REFUND_LIST} target="_self">
                <span>
                  <img
                    src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                    alt="img"
                  />
                </span>
                <small>Refunds</small>
              </Link>
            </li>
          )}
          <li className="s-sidebar__nav-link">
            <Link to={PATHS.ACCOUNTS_REFUNDS_ANALYTICS} target="_self">
              <span>
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                  alt="img"
                />
              </span>
              <small>Refunds Analytics</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to={PATHS.FIELD_SUPPORT_EMPLOYEES_INSTALLATIONS} target="_self">
              <span>
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1549268125/Installationzone.fw.png"
                  alt="img"
                />
              </span>
              <small>Employees Installations</small>
            </Link>
          </li>

          {(email === 'operationsDept@tizeti.com' ||
            email === 'emmanuel.esievo@tizeti.com' ||
            email === 'emmanuel.gbassi@tizeti.com' ||
            email === 'victor.adetunji@tizeti.com' ||
            email === 'muiz.abass@tizeti.com' ||
            email === 'ihechi.obioha@tizeti.com' ||
            email === 'ikenna.ndumele@tizeti.com' ||
            email === 'Ridwan.yahaya@tizeti.com' ||
            email === 'abraham.iyaluegbeghe@tizeti.com') && (
            <li className="s-sidebar__nav-link">
              <Link to="/shared/release-report" target="_self">
                <span className="mr-2">
                  <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971010/Group_2.1.png" alt="img" />
                </span>
                Release Report
              </Link>
            </li>
          )}
          <li className="s-sidebar__nav-link">
            <Link to="/shared/basestations" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
              </span>
              <small>All Basestations</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            <Link to="/field-support/basestations" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="img" />
              </span>
              <small>BaseStations</small>
            </Link>
          </li>

          <li className="s-sidebar__nav-link">
            {' '}
            <Link to="/field-support/leads" target="_self">
              <span>
                <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971011/Group.png" alt="img" />
              </span>
              <small>Leads</small>
            </Link>
          </li>

          {checkCountry()}
          {country != 'CIV' && (
            <>
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/no-los" target="_self">
                  <span>
                    <img src="https://res.cloudinary.com/tizeti/image/upload/v1542971012/Group-1.png" alt="" />
                  </span>
                  <small>No LOS</small>
                </Link>
              </li>
              <li className="s-sidebar__nav-link d-none d-md-block">
                {' '}
                <Link to="/field-support/promoter-score" target="_self">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Net Promoter Score</small>
                </Link>
              </li>
              <li className="s-sidebar__nav-link mt-5">
                {' '}
                <Link to="/field-support/admin-requests" target="_self" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                  </span>
                  <small>Admin Requests</small>
                </Link>
              </li>
              {roleId > 2 ? <BudgetSideBar link={PATHS.FIELD_SUPPORT_BUDGET} name="Budget" /> : null}
              {roleId > 2 && (
                <li className="d-none d-md-block">
                  <a
                    href={PATHS.FIELD_SUPPORT_SUPPORT_RECRUITMENT}
                    rel="noopener noreferrer"
                    target="_blank"
                    className=""
                  >
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1555319728/recruitment_1.svg"
                        alt="img"
                      />
                    </span>{' '}
                    Recruitment
                  </a>
                </li>
              )}

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to={PATHS.FIELD_SUPPORT_PAYSLIP} target="_self" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                  </span>
                  <small>Payslip</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/kpi-monitor" target="_self" className="make-bold">
                  <span>
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1553072952/KPIMonitor.svg" alt="img" />
                  </span>
                  <small>KPI Monitor</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                <Link to="/field-support/purchase-order" target="_self" className="">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  Purchase Order
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/issue" target="_self" className="text-danger make-bold">
                  <span>
                    <img
                      src="https://res.cloudinary.com/teewhy/image/upload/v1548923430/r_d-envelop.fw.png"
                      alt="img"
                    />
                  </span>
                  <small>Report an Issue</small>
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/escalate" target="_self" className="text-danger make-bold">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  Escalate
                </Link>
              </li>

              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/learning-portal" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  Learning Portal
                </Link>
              </li>
              <li className="s-sidebar__nav-link">
                {' '}
                <Link to="/field-support/terms-and-conditions" target="_self">
                  <span className="mr-2">
                    <img src="https://res.cloudinary.com/teewhy/image/upload/v1557141046/Schedule.png" alt="img" />
                  </span>
                  Terms and Conditions
                </Link>
              </li>

              {roleId > 2 && (
                <li className="s-sidebar__nanpm i js-base64v-link">
                  {' '}
                  <Link to="/field-support/request-feature" target="_self" className="make-bold">
                    <span>
                      <img
                        src="https://res.cloudinary.com/teewhy/image/upload/v1548923169/configr_d.fw.png"
                        alt="img"
                      />
                    </span>
                    <small>Request a Feature</small>
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default FieldSupportSideBar;
