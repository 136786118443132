import React, { useState } from 'react';
import AccountsFirsTransactionsTable from './AccountsFirsTransactionsTable';
import { useFirsTransactions } from '../../../../../hooks/accounts/firs/useFirsTransactions';
import CustomCsvDownload from '../../../shared/CustomCsvDownload';
import { firsTransactionsHeader } from '../../../../../utils/accounts/helpers';
import { Modal } from 'react-bootstrap';

const AccountsFirsTransactionsBody = () => {
  const {
    count,
    lists,
    listsCache,
    searchFirsTable,

    handleCsvUpload,
    isLoading,
    ishotspotUploading,
    handleEutelsatCsvUpload,

    isEutelsatUploading,
    getAllDedicatedInvoice,
    isReverseLoading,
    reverseTransaction,
    isFullReverseLoading,
    reverseFullTransaction,

    handleManualPosting,
    isManualPosting,

  } = useFirsTransactions();

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [view, setView] = useState(false);

  const [file, setFile] = useState({ name: '' });
  const [eutelsatFile, setEutelsatFile] = useState({ name: '' });

  return (
    <div className="mt-5 p-3">
      <div className="container-fluid pt-4 ml-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between w-100 mb-3">
            <div className="d-flex">
              <input
                className="form-control mr-auto empty pl-2"
                type="text"
                placeholder="Search by name, email, product ID, product Name"
                name="optionValue"
                onChange={(e) => {
                  searchFirsTable(e.target.value);
                }}
                style={{
                  width: '300px',
                  height: '40px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                }}
                readOnly={false}
              />
            </div>
          </div>

          <div className="col-12 d-flex justify-content-between w-100 mb-3">
            <div className="d-flex">
              <input
                className="form-control mr-auto empty pl-2"
                type="date"
                placeholder="Search by date"
                name="date"
                onChange={(e) => setDate(e.target.value)}
                style={{
                  width: '300px',
                  height: '40px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                }}
              />

              <button onClick={() => getAllDedicatedInvoice(date)} className="btn btn-green shadow-md ml-3">
                Search date
              </button>
            </div>
          </div>
          <div className="col-12 d-flex mt-2">
            <CustomCsvDownload
              data={listsCache || []}
              headers={firsTransactionsHeader}
              filename={`Firs Transactions`}
              classname="btn btn-green shadow-md"
              textcolor="white"
              text="Download Data"
            />
          </div>

          <div className="col-12 d-flex mt-4">
            <input
              className="form-control empty pl-2"
              type="file"
              placeholder="upload csv"
              name="form"
              accept=".csv"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
            />

            <button
              disabled={isEutelsatUploading || ishotspotUploading}
              onClick={() => handleCsvUpload(getAllDedicatedInvoice, file)}
              className="btn btn-green shadow-md ml-3"
            >
              {ishotspotUploading ? 'uploading...' : 'Upload Hotspot Data'}
            </button>
          </div>
          <div className="col-12 d-flex mt-4">
            <input
              className="form-control empty pl-2"
              type="file"
              placeholder="upload csv"
              name="form"
              accept=".csv"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setEutelsatFile(e.target.files[0]);
              }}
              style={{
                width: '300px',
                height: '40px',
                border: '1px solid gray',
                borderRadius: '5px',
              }}
              readOnly={false}
            />

            <button
              disabled={isEutelsatUploading || ishotspotUploading}
              onClick={() => handleEutelsatCsvUpload(getAllDedicatedInvoice, eutelsatFile)}
              className="btn btn-green shadow-md ml-3"
            >
              {isEutelsatUploading ? 'uploading...' : 'Upload Eutelsat Data'}
            </button>
          </div>

          <div className="mt-4">


            <button
              disabled={isEutelsatUploading || ishotspotUploading}
              onClick={() => setView(true)}
              className="btn btn-green shadow-md ml-3"
            >
              Manual Posting
            </button>
          </div>
          <div className="p-0 col-12 d-flex justify-content-between mt-5 ml-3">
            {listsCache && listsCache.length > 0 ? (
              <div>
                Transaction Count: <span className="badge badge-secondary mr-4">{count}</span>
              </div>
            ) : null}
          </div>
          <AccountsFirsTransactionsTable
            lists={lists}
            isLoading={isLoading}
            isReverseLoading={isReverseLoading}
            reverseTransaction={reverseTransaction}
            isFullReverseLoading={isFullReverseLoading}
            reverseFullTransaction={reverseFullTransaction}
          />
        </div>
      </div>
      <ManualTransactionReversal
        view={view}
        setView={setView}
        handleManualPosting={handleManualPosting}
        isManualPosting={isManualPosting}

      />
    </div>
  );
};

export default AccountsFirsTransactionsBody;


const productList = [
  { "Product": "Dedicated Sales Revenue", "Product ID": "4013" },
  { "Product": "Dedicated Setup Revenue", "Product ID": "4012" },
  { "Product": "Eutelsat Revenue", "Product ID": "4022" },
  { "Product": "Expresswifi Customer Revenue", "Product ID": "4014" },
  { "Product": "Expresswifi Subscription Account", "Product ID": "4003" },
  { "Product": "Hotspot", "Product ID": "4002" },
  { "Product": "Material Purchase", "Product ID": "5000" },
  { "Product": "Miscellaneous Income", "Product ID": "4900" },
  { "Product": "Relocation Revenue", "Product ID": "4004" },
  { "Product": "Sales", "Product ID": "4000" },
  { "Product": "Setup Revenue", "Product ID": "4001" },
  { "Product": "Wifi call Sales Account", "Product ID": "4008" }
];



const ManualTransactionReversal = ({ view, setView, isManualPosting, handleManualPosting }) => {
  const [amount, setAmount] = useState(null);
  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [reference, setReference] = useState(null);
  const [description, setDescription] = useState(null);
  const [product, setProduct] = useState({
    productId: null,
    productName: null
  });

  const handleProductChange = (e) => {
    const selectedProduct = productList.find(p => p['Product ID'] === e.target.value);
    setProduct({
      productId: selectedProduct['Product ID'],
      productName: selectedProduct['Product']
    });
  };

  const handleClose = () => {
    setView(false);
  };

  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: '2rem' }}>Manual Posting</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleManualPosting(name, amount, date, reference, product, description);
        }}
        className="col-md-12"
      >

        <div className="col-12 mb-3 mt-3">
          <h6 className="copy-font ml-0">Customer Name</h6>
          <input
            name="name"
            id="name"
            type="text"
            className="form-control mx-auto border"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="col-12 mb-3 mt-3">
          <h6 className="copy-font ml-0">Date</h6>
          <input
            name="date"
            id="date"
            type="date"
            className="form-control mx-auto border"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="col-12 mb-3 mt-3">
          <select
            name="product"
            id="product"
            onChange={handleProductChange}
            value={product.productId}
            className="form-control mx-auto border"
            required
          >
            <option value="">Select Product</option>
            {productList.map((product) => (
              <option key={product['Product ID']} value={product['Product ID']}>
                {product['Product']}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 mb-3 mt-3">
          <h6 className="copy-font ml-0">Amount</h6>
          <input
            name="amount"
            id="amount"
            type="number"
            className="form-control mx-auto border"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>

        <div className="col-12 mb-3 mt-3">
          <h6 className="copy-font ml-0">Reference</h6>
          <input
            name="reference"
            id="reference"
            type="text"
            className="form-control mx-auto border"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
            required
          />
        </div>
        <div className="col-12 mb-3 mt-3">
          <h6 className="copy-font ml-0">Transaction Description</h6>
          <input
            name="description"
            id="description"
            type="text"
            className="form-control mx-auto border"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="text-center my-3">
          {isManualPosting ? (
            <button type="button" className="btn btn-green py-2 px-5 p-28" disabled>
              Posting...
            </button>
          ) : (
            <button type="submit" className="btn btn-green py-2 px-5 p-2">
              Post
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};
